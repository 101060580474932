import React, {useContext } from "react";
import { IntersectionContext } from "../layout/intersection";
import { motion } from "framer-motion";

const durationTimer = 0.1;

export const AppearAnimTitle = ({
  children,
}) => {
  const { inView } = useContext(IntersectionContext);

  const variants = {
    hidden: {
      opacity: 0,
      y:50,
    },
    show: {
      opacity: 1,
      y:0,
      transition: {
        duration: durationTimer,
        type: 'spring',
        damping: 8,
        stiffness: 50,
        mass:2,
        delayChildren:0.7,
        staggerChildren: 0.05
      },
    },
    fade: {
      opacity: 0,
      y:50,
    },
  };

  return (
    <motion.div
    center={ "y" }
    height={ 21 }
    width={ "50%" }
    background={ "" }
    className="quote_text font-Gilroy_bold fill-green text-2xl xxs:text-4xl md:text-5xl xxl:text-6xl"
    style={{
      display: "flex", // Set the display value to flex
      justifyContent: "left", // Center all children elements on the x axis
      flexWrap: "wrap",
    }}
          initial="fade"
          animate={inView ? "show" : "hidden"}
          variants={variants}
          >
      {children}
    </motion.div>
  );
};