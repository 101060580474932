import React from 'react';

const ProjectSection = ({ children }) => {
    return ( 
        <>
        <article className="flex flex-col md:flex-row overflow-visible">
        {children}
        </article>
        </>
    )
}

export default ProjectSection