import React, { useEffect, useState, useRef } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo"
import { useDispatch } from 'react-redux';
import { AppearAnimText } from '../components/animations/text_fade';
import ProjectSection from '../components/layout/ProjectSection';
import { IntersectionObserver } from "../components/layout/intersection";
import { Button } from '../components/UI/button/button';
import Img from "gatsby-image/withIEPolyfill"
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { connect } from 'react-redux';
import { isBrowser } from "react-device-detect";
import { AppearAnimTitle } from "../components/animations/projects_title";
import FooterLayout from "../components/footer/footer";
import Link from "gatsby-link" // not error 
import ReactPlayer from 'react-player/lazy'
import Smoothscroll from "../components/Smoothscroll/smoothscroll";
import { WritingObserver } from "../components/animations/writing";
import Linkwrapper from "../components/linkWrapper/linkWrapper";

function ProjectTemplate({ data, alpha, location }) {

  const { scrollYProgress, scrollY } = useViewportScroll();

  return (
    <>
      <SEO title={data.markdownRemark?.frontmatter?.titre} />
      {/* <SummaryMenu data={data} scrollY={scrollY} /> */}
      <FormsBgProjects data={data} scrollYProgress={scrollYProgress} />
      <FormsBgProjects2 data={data} scrollYProgress={scrollYProgress} alpha={alpha} />
      <FormsBgProjectsBleu data={data} scrollYProgress={scrollYProgress} />
      <FormsBgGris data={data} scrollYProgress={scrollYProgress} />
      <FormsBgGreen data={data} scrollYProgress={scrollYProgress} />
      <Smoothscroll className="willChange flex justify-center flex-col items-center w-full z-20">
        <Header data={data} scrollY={scrollY} />
        <Content data={data} scrollY={scrollY} />
        <NosHistoiresSection data={data} />
        <FooterLayout />
      </Smoothscroll>
    </>
  )
}

const NosHistoiresSection = ({ data }) => {
  return (
    <>
      <section className="w-full flex items-center justify-center my-20">
        <Link to="/projets/" className="inline">
          <Linkwrapper mouseIcon="discover">
            <div className="flex flex-col text-center items-center">
              <h1 className="font-Gilroy_ExtraBoldItalic stroke text-4xl lg:text-4xl xl:text-6xl leading-none break-normal">Toutes<br></br>nos</h1>
              <div className="w-3/4 -mt-3 lg:-mt-6"><WritingObserver reset={true} text="histoires_footer" /></div>
            </div>
          </Linkwrapper>
        </Link>
      </section>
    </>
  )
}

const Header = ({ data, scrollY }) => {

  const { markdownRemark } = data || null // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const dispatch = useDispatch();

  //mobile / desktop anim values
  const AnimationValue = 0;

  const yvalue = useTransform(scrollY, [0, 460], [AnimationValue, 400]);
  const yspring = useSpring(yvalue, { damping: 15, stiffness: 10, mass: 0.2 })

  const opacityvalue = useTransform(scrollY, [0, 150], [1, 0]);

  const scalevaluevalue = useTransform(scrollY, [0, 460], [1, 1.1]);
  const scalespring = useSpring(scalevaluevalue, { damping: 15, stiffness: 10, mass: 0.2 })

  const opacityvaluespring = useSpring(opacityvalue, { damping: 15, stiffness: 10, mass: 0.2 })

  const titleArray = Array.from(frontmatter.titre)

  const animation = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      y: 0,

    },
    fade: {
      opacity: 0,
      y: 50,
    },
  };

  function changeFilter(tagname) {
    dispatch({ type: 'setTags', value: [tagname] });
  }

  return (
    <motion.header
      transition={{ duration: 0.1 }}
      style={{ y: isBrowser ? yspring : 0, opacity: opacityvaluespring }}
      className="w-full relative transform lg:translate-y-6 z-20 top-0 flex items-start justify-center h-screen">
      <section className="flex w-full pb-0 pt-32 lg:pt-32 xl:pt-40 lg:h-auto lg:justify-between">
        <div className="w-full flex flex-col lg:flex-row lg:gap-12 items-center relative lg:justify-center">
          {/* <div className="flex w-11/12 lg:w-2/4 justify-center pb-0 lg:pb-0 items-end lg:items-center lg:justify-end mr-0 lg:mr-12 ">
            <div
              className="flex justify-center items-center border-b-4 border-gray-400 rounded-full pointer-events-none overflow-hidden h-64 w-64  sm:h-70 sm:w-70 lg:h-90 lg:w-90  bg-gradient-green"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.3 }}
                className="rounded-full border-gray-300 pointer-events-none overflow-hidden h-80 w-80 ">
                {isBrowser &&
                  <ReactPlayer
                    width='100%'
                    height='auto'
                    loop={true}
                    playing={true}
                    controls={false}
                    muted={true}
                    url={frontmatter.video.publicURL} >
                  </ReactPlayer>
                }
                {isMobile &&
                  <Img
                    fluid={frontmatter.fimage.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={"image"}
                  />
                }
              </motion.div>
            </div>
          </div> */}
          <div className="flex px-4 lg:px-0 pt-1 lg:pt-0 flex-col lg:flex-row w-4/5 lg:w-3/4 xl:w-2/3 xxl:w-2/3 gap-2 lg:gap-5 justify-between">
            <div className="flex flex-col flex-no-wrap justify-center">
              <AppearAnimTitle>
                {titleArray.map((letter, index) => (
                  <motion.div
                    key={index}
                    width={"auto"} // Set the width to the width of the letter
                    height={12} // Set the height to the height of the text
                    background={""}
                    className=" text-tactileGreen"
                    style={{ position: "relative" }} // Position elements
                    variants={animation}
                  >
                    {letter === " " ? "\u00A0" : letter}
                  </motion.div>))}
              </AppearAnimTitle>
              <div className="flex mt-5 lg:mt-16 flex-col leading-none flex-no-wrap pb-2">
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.7 }}
                  className="font-Gilroy_extrabold tracking-widest2 text-black uppercase text-xxs lg:text-xs pb-2">client</motion.p>
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.7 }}
                  className="font-Gilroy_ExtraBoldItalic text-black tracking-normal text-xl  lg:text-3xl">{frontmatter.client}</motion.p>
              </div>
            </div>
            <div className="relative items-start lg:w-1/2 xxl:w-1/3 mb-2 flex mt-0 lg:mt-5 flex-col flex-no-wrap justify-between" >
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.7 }}
                className=" leading-none pt-4 font-Gilroy_BlackItalic text-black tracking-normal text-2.5xl sm:pt-12 lg:text-2xl lg:pt-5 xxl:text-3xl pb-2">{frontmatter.description}</motion.p>
              <motion.div
                transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.7, staggerChildren: 0.1 }}
                className="flex flex-wrap flex-row items-end gap-0 mt-4 sm:mt-8">
                {frontmatter.tags.map((item, i) =>
                  <div onClick={() => changeFilter(item)} className="cursor-pointer" key={i} >
                    <Link className="cursor-pointer" to={`/projets/`}>
                      <motion.p
                        initial={{ y: 20, opacity: 0, scaleY: 0 }}
                        animate={{ y: 0, opacity: 1, scaleY: 1 }}
                        transition={{ type: "spring", damping: 5, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.7 }}
                        className="font-Gilroy_semibold text-tactileGray tracking-wide text-xs lg:text-sm mx-1 hover:text-tactileGreen transition-colors duration-500" >#{item}</motion.p>
                    </Link>
                  </div>
                )}
              </motion.div>
              {frontmatter.block.map((block, index) => (
                (block.template === "projet-bouton" &&
                  <div key={index} className="w-full -mb-24 bottom-0 right-0 flex flex-col justify-between items-center lg:items-left absolute">
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ type: "spring", damping: 10, mass: 0.5, stiffness: 50, duration: 0.35, delay: 0.4 }}
                      className="flex flex-col w-full justify-between h-full pt-16" >
                      <Button link={block.projets_bouton_url}>{block.projets_bouton_texte}</Button>
                    </motion.div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      </section>
    </motion.header>
  )
}

const Content = ({ data, scrollY }) => {
  console.log('Content.data', data)
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const animation = {
    hidden: {
      opacity: 0, y: 70,
      transition: {
        type: 'tween',
        duration: 0.5,
      }
    },
    show: {
      opacity: 1, y: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 60,
        duration: 0.5,
        staggerChildren: 0.35
      }
    }
  }

  const animationfromleft = {
    hidden: {
      opacity: 0, x: -70,
      transition: {
        type: 'tween',
        duration: 0.5,
      }
    },
    show: {
      opacity: 1, x: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 60,
        duration: 0.5,
        staggerChildren: 0.35
      }
    },
    exit: {
      opacity: 0,
      transition: {
        type: 'tween',
        duration: 0.5,
      }
    },
  }

  function hideCursor() {
    dispatch({ type: 'GetMouseState', value: 0 });
  }

  function showCursor() {
    dispatch({ type: 'GetMouseState', value: 1 });
  }

  const ref = useRef();
  const [windowHeightState, setWindowHeightState] = React.useState(0)
  const [elementHeight, setElementHeight] = useState(null);

  useEffect(() => {
    if (!ref.current) return;
    const windowGlobal = typeof window !== 'undefined' && window

    function setValues() {
      setElementHeight(ref.current.clientHeight);
      setWindowHeightState(windowGlobal.innerHeight);
    };

    windowGlobal.addEventListener('resize', setValues);
    setValues();
    return () => windowGlobal.removeEventListener('resize', setValues);
  }, [elementHeight]);


  //let elementInnerHeight = elementHeight;
  let WindowHeight = windowHeightState;

  const yvalue = useTransform(scrollY, [0, 460], [(-WindowHeight * 0.15), 0]);
  //const yspring = useSpring(yvalue, { damping: 100, stiffness: 50, mass: 0.2, })

  const yvalueMobile = useTransform(scrollY, [0, 460], [0, 0]);
  //const yspringMobile = useSpring(yvalueMobile, { damping: 100, stiffness: 50, mass: 0.2, })

  const opacityvalue = useTransform(scrollY, [0, 560], [0.3, 1]);
  //const opacityspring = useSpring(opacityvalue, { damping: 100, stiffness: 50, mass: 0.2, })

  const scalevalue = useTransform(scrollY, [0, 560], [0.75, 1]);
  //const scalespring = useSpring(scalevalue, { damping: 100, stiffness: 50, mass: 0.2, })
  const dispatch = useDispatch();
  //console.log(frontmatter.block)
  return (
    <motion.section
      ref={ref}
      initial={{ opacity: isBrowser ? 0.5 : 1 }}
      style={{ opacity: isBrowser ? opacityvalue : 1 }}
      className="w-full overflow-hidden lg:w-4/5 pt-0 lg:pt-0 top-0 mb-0 translate-calc">
      {frontmatter.block.map((block, index) => (
        <section key={index} >
          {(block.template === "block-image" && block.block_image?.childImageSharp?.fluid) &&
            <Img
              fluid={block.block_image.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="image"
            />
          }
          {(block.template === "block-video-vimeo-yt" && block.video_url !== null) &&
            <div style={{ backgroundColor: block.couleur_de_fond_video !== null && block.couleur_de_fond_video }} className="w-full h-screen flex justify-center items-center pb-0 mdpb-20" >
              <ReactPlayer
                width='100%'
                height=''
                style={{ maxWidth: "1280px", maxHeight: "720px" }}
                className="h-screen-1/3 lg:h-screen pt-0"
                loop={false}
                playing={false}
                controls={true}
                muted={false}
                onMouseEnter={() => hideCursor()}
                onMouseLeave={() => showCursor()}
                url={block.video_url} >
              </ReactPlayer>
            </div>
          }
          {(block.template === "block-video-upload" && block.video_upload !== null) &&
            <ReactPlayer
              width='100%'
              height='auto'
              className="pt-0 h-screen"
              loop={true}
              playing={true}
              controls={false}
              muted={true}
              url={block.video_upload.publicURL} >
            </ReactPlayer>
          }
          {block.template === "block-paragraphe" &&
            <section style={{ backgroundColor: block.couleur_de_fond !== null && block.couleur_de_fond }} className="w-full overflow-visible">
              <IntersectionObserver>
                <AppearAnimText>
                  <ProjectSection>
                    <motion.div
                      variants={animationfromleft}
                      style={{ color: block.couleur_de_texte !== null ? block.couleur_de_texte : "#FFF" }}
                      className="w-full lg:w-1/2 text-white font-Gilroy_bold tracking-widest pt-10 pb-0 lg:pt-20 lg:pb-20 pl-10 lg:pl-48 uppercase text-base lg:text-sm">{block.titre_de_section !== null && block.titre_de_section}</motion.div>
                    <motion.div
                      variants={animation}
                      style={{ color: block.couleur_de_texte !== null ? block.couleur_de_texte : "#FFF" }}
                      className="w-full lg:w-1/2 text-white font-Gilroy_bold tracking-wide pt-10 pb-10 lg:pt-20 lg:pb-20 px-10 lg:px-0 lg:pr-48 text-sm lg:text-base">
                      <>
                        <motion.div variants={animation} className="font-Gilroy_medium text-3xl lg:text-4xl leading-none " >
                          {block.titre_paragraphe !== null && block.titre_paragraphe}
                        </motion.div>
                        <br></br>
                      </>
                      <motion.div className="font-Gilroy_medium block_paragraphe" variants={animation}>
                        {block.paragraphe !== null &&
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.paragraphe,
                            }}
                          />
                        }
                      </motion.div>
                    </motion.div>
                  </ProjectSection>
                </AppearAnimText>
              </IntersectionObserver>
            </section>
          }
        </section>
      ))}
    </motion.section>
  )
}

const FormsBgProjects = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -350]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 70]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 pt-16 -ml-16 left-0 w-1/3 z-10">
        <Img
          fluid={data.bgProjects1.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

const FormsBgProjects2 = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -350]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 50]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 -mr-24 lg:mr-0 xxl:mr-16 right-0 w-40 z-20 lg:z-40">
        <Img
          fluid={data.bgProjects2.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

const FormsBgProjectsBleu = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -850]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 250]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 pt-64 -ml-32 lg:-ml-10 xl:ml-0 xxl:ml-16 left-0 w-40 z-20 lg:z-40">
        <Img
          fluid={data.bgProjects3.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

const FormsBgGris = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -500]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 50]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 pt-64 pt-64 -mr-20 right-0 w-60 z-10">
        <Img
          fluid={data.bgProjects4.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

const FormsBgGreen = ({ data, scrollYProgress }) => {

  const yvalue = useTransform(scrollYProgress, [0, 1], [0, -600]);
  const yspring = useSpring(yvalue, { damping: 20, stiffness: 5, mass: 0.2 })

  const yvalueMobile = useTransform(scrollYProgress, [0, 0.1], [0, 100]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 20, stiffness: 5, mass: 0.2 })

  return (
    <>
      <motion.div
        transition={{ duration: 0.1 }}
        style={{ y: isBrowser ? yspring : yspringMobile }}
        className="forme_opti fixed pointer-events-none top-0 pt-64 -mr-40 lg:-mr-32 xxl:mr-6 right-0 w-60 z-20 lg:z-40">
        <Img
          fluid={data.bgProjects5.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="forme"
        />
      </motion.div>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    bgProjects1: file(relativePath: { eq: "UI/projets/forme_grise.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    bgProjects2: file(relativePath: { eq: "UI/projets/forme_texture.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    bgProjects3: file(relativePath: { eq: "UI/projets/forme_texture_bleu.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    bgProjects4: file(relativePath: { eq: "UI/projets/forme-grise-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    bgProjects5: file(relativePath: { eq: "UI/projets/forme_texture_green.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }){
      html
      frontmatter {
        path
        nouveau
        cover { 
          childImageSharp {
          fluid(quality: 95, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
         }
        }
        video 
        {
          publicURL
        }
        titre
        client
        description
        tags
        block {
          projets_bouton_url
          projets_bouton_texte
          couleur_de_fond
          couleur_de_texte
          template
          titre_de_section
          titre_paragraphe
          paragraphe
          couleur_de_fond_video
          video_url
          video_upload
          {
            publicURL
          }
          block_image {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
         }
        }
      }
  }
`

export default connect(state => ({
  scrollHeight: state.Scrollvalue.scrollHeight,
  alpha: state.Orientation.alpha,
  gamma: state.Orientation.gamma,
  beta: state.Orientation.beta,

}), null)(ProjectTemplate);

// export const pageQuery = graphql`
//   query BlogPostByID($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       html
//       frontmatter {
//         title
//      }
//     }
//   }
// `
